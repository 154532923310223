<template>
    <v-container fluid>
        <v-row no-gutters class="d-block">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                <v-card>
                    <v-card-title>
                        <v-row>

                            <v-col cols="6" sm="3" md="3" lg="3" xl="3">
                                <v-btn color="success" v-if="permisos[2]" small outlined @click="personaNuevo()">
                                    <v-icon>add</v-icon>
                                    Nuevo
                                </v-btn>
                            </v-col>

                            <v-col cols="6" sm="3" md="3" lg="3" xl="3">

                                <v-btn color="info" small outlined @click="personasGet()">
                                    <v-icon>refresh</v-icon>
                                    Refrescar
                                </v-btn>

                            </v-col>
                            <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pt-0">
                                <v-text-field
                                    hide-details
                                    v-model="search"
                                    label="Buscar por Identidad, Nombres, Apellidos y pulse en el botón."
                                    clearable>
                                    <template v-slot:append>
                                        <v-btn
                                            color="secondary" small outlined
                                            @click="personasBuscar()">
                                            <v-icon>search</v-icon>
                                        </v-btn>
                                    </template>
                                    >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table
                            dense
                            class="elevation-3"
                            :headers="tableHeaders"
                            :items="personas"
                            :loading="loading"
                            item-key="persona_id"
                            fixed-header
                            height="65vh"
                            :hide-default-footer="true"
                            disable-pagination
                        >
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td class="text-left">{{ item.numero_identidad }}</td>
                                    <td class="text-left">{{ item.nombre }}</td>
                                    <td class="text-left">{{ item.apellido }}</td>
                                    <td class="text-left">{{ item.correo }}</td>
                                    <td class="text-left">{{ item.telefono }}</td>
                                    <td class="text-left">{{ item.ciudad }}</td>
                                    <td class="text-left">{{ item.direccion }}</td>
                                    <td class="text-center">
                                        <v-btn color="info" small outlined @click="personasEdit(item)" title="Editar" v-if="permisos[3]">
                                            <v-icon>edit</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>
                            <template v-slot:no-data>
                                <v-alert dense type="warning" outlined class="text-center mt-3">
                                    No existen registros.
                                </v-alert>
                            </template>
                        </v-data-table>
                        <Pagination :pagination="pagination" @setCambiarPagina="setCambiarPagina"
                                    v-if="pagination.total"/>

                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>

import Auth from "@/services/auth";
const Pagination = () => import("@/pages/Components/Pagination.vue");

export default {
    name: 'ParticipantesAdministradoresLista',
    components: {Pagination},
    data() {
        return {
            permisos: [],
            personas: [],
            pagination: {},
            pageActual: 1,
            rowsPage: this.$store.state.arrayRowsPages[0],
            search: "",
            loading: false,
            filtros: {
                cat_tipo_persona_id: 2
            },
            tableHeaders: [
                {
                    text: 'N.° Identidad',
                    value: 'numero_identidad',
                    align: 'left',
                    sortable: true
                },
                {
                    text: 'Nombres',
                    value: 'nombre',
                    align: 'left',
                    sortable: true
                },
                {
                    text: 'Apellidos',
                    value: 'apellido',
                    align: 'left',
                    sortable: true
                },
                {
                    text: 'Correo',
                    value: 'correo',
                    align: 'left',
                    sortable: true
                },
                {
                    text: 'Teléfono',
                    value: 'telefono',
                    align: 'left',
                    sortable: true
                },
                {
                    text: 'Ciudad',
                    value: 'ciudad',
                    align: 'left',
                    sortable: true
                },
                {
                    text: 'Dirección',
                    value: 'direccion',
                    align: 'left',
                    sortable: true
                },
                {
                    text: 'Acciones',
                    align: 'center',
                    sortable: false
                },
            ],
        }
    },

    methods: {
        personaNuevo() {
            this.$router.push({name: 'ParticipantesAdministradoresNuevo'});
        },
        personasBuscar() {
            this.pageActual = 1;
            this.personasGet();
        },
        personasGet() {
            this.loading = true;
            // filtros
            let params = {filtros: this.filtros, busqueda: this.search, page: this.pageActual, rowsPage: this.rowsPage};
            window.axios.get('/personas', {params}).then(response => {
                if (response.data.success) {
                    let data = response.data.data;
                    this.personas = data.personas.data;
                    this.pagination = data.pagination;

                } else if (response.data.success === false) {
                    this.$toast.error(response.data.message);
                }
            })
                .catch(error => {
                    console.log(error)
                    this.$toast.error("No se pudo listar datos.");
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        personasEdit(persona) {
            if (typeof persona == "object") {
                let token = window.btoa(persona.persona_id);
                this.$router.push({name: 'ParticipantesAdministradoresEditar', query: {token}});
            } else {
                this.$toast.error("Parametro no valido.");
            }
        },
        setCambiarPagina(data) {
            this.pageActual = data.page;
            this.rowsPage = data.rowsPage;
            this.personasGet();
        },
    },
    created() {
        Auth.checkPermisos(this.$route.path).then((res) => {
            this.permisos = res;
            this.personasGet();
        });
    }
}
</script>

<style>

</style>
